import Simplebar from 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

const initBlock = async function( $block ) {
	const { Swiper } = await import( 'swiper' );
	const { Navigation, Pagination, Thumbs } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const sliderReviews = () => {
		const $sliderReviewsWrap = $block.find( '.slider-reviews-wrap' );
		if ( !$sliderReviewsWrap.length ) { return false; }
		const $sliderReviews = $sliderReviewsWrap.find( '.swiper' );
		const $buttonNext = $sliderReviewsWrap.find( '.slider-reviews__arrow-next' );
		const $buttonPrev = $sliderReviewsWrap.find( '.slider-reviews__arrow-prev' );
		const $pagination = $sliderReviewsWrap.find( '.slider-reviews__pagination--desktop' );
		const $paginationMobile = $sliderReviewsWrap.find( '.slider-reviews__pagination--mobile' );

		let swiperReviews = null;

		const breakpoint = 576;
		let isMobile = window.innerWidth < breakpoint;
		const updatePaginationBullets = ( direction ) => {
			if ( !isMobile ) {
				const $wrapper = $pagination.find( '.simplebar-content-wrapper' );
				let scrollAmount = direction === 'forward' ? 28 : -28;
				$wrapper[0].scrollTo( {
					top: $wrapper[0].scrollTop + scrollAmount,
					behavior: 'smooth'
				} );
			} else {
				const $wrapper = $paginationMobile;
				let scrollAmount = direction === 'forward' ? 28 : -28;
				$wrapper[0].scrollTo( {
					left: $wrapper[0].scrollLeft + scrollAmount,
					behavior: 'smooth'
				} );
			}
		};

		const initSlider = function( isMobile ) {
			const paginationElement = isMobile ? $paginationMobile[0] : $pagination[0];
			const swiperOptions = {
				modules: [Navigation, Pagination],
				direction: 'vertical',
				navigation: {
					nextEl: $buttonNext[0],
					prevEl: $buttonPrev[0]
				},
				pagination: {
					el: paginationElement,
					clickable: true,
					bulletClass: 'pagination-bullet',
					bulletActiveClass: 'pagination-bullet-active',
					verticalClass: 'pagination-vertical'
				},
				init: true,
				loop: false,
				speed: 500,
				spaceBetween: 36,
				simulateTouch: true,
				allowTouchMove: true,
				centeredSlides: false,
				slidesPerView: 'auto',
				autoHeight: true,
				on: {
					init: function() {
						new Simplebar( $pagination[0] );
					},
					slideChange: function( swiper ) {
						const direction = swiper.realIndex > swiper.previousIndex ? 'forward' : 'backward';
						updatePaginationBullets( direction );
					}
				},
				breakpoints: {
					576: {
						centeredSlides: true,
					}
				}
			};

			if ( swiperReviews ) {
				swiperReviews.destroy( true, true );
			}

			swiperReviews = new Swiper( $sliderReviews[0], swiperOptions );
		};

		initSlider( isMobile );

		window.addEventListener( 'resize', () => {
			const currentIsMobile = window.innerWidth < breakpoint;
			if ( currentIsMobile !== isMobile ) {
				isMobile = currentIsMobile;
				initSlider( isMobile );
			}
		} );
	};
	sliderReviews();

	const sliderTestimonials = () => {
		const $sliderTestimonialsWrap = $block.find( '.slider-testimonials-wrap' );
		if ( !$sliderTestimonialsWrap.length ) { return false; }
		const $sliderTestimonialsMain = $sliderTestimonialsWrap.find( '.slider-testimonials-main__inner' );
		const $sliderTestimonialsThumb = $sliderTestimonialsWrap.find( '.slider-testimonials-thumb__sub-inner' );
		const $buttonNext = $sliderTestimonialsWrap.find( '.slider-arrow--next' );
		const $buttonPrev = $sliderTestimonialsWrap.find( '.slider-arrow--prev' );
		const loop = true;

		const swiperTestimonialsThumb = new Swiper( $sliderTestimonialsThumb[0], {
			modules: [Thumbs, Navigation],
			init: false,
			loop: loop,
			speed: 500,
			spaceBetween: 16,
			slidesPerView: 3,
			centeredSlides: false,
			allowTouchMove: true,
			watchSlidesProgress: true,
			breakpoints: {
				1024: {
					allowTouchMove: false,
				}
			}
		} );
		swiperTestimonialsThumb.init();

		const swiperTestimonialsMain = new Swiper( $sliderTestimonialsMain[0], {
			modules: [Thumbs, Navigation],
			init: false,
			loop: loop,
			speed: 500,
			thumbs: {
				swiper: swiperTestimonialsThumb,
			},
			navigation: {
				nextEl: $buttonNext[0],
				prevEl: $buttonPrev[0]
			},
			spaceBetween: 40,
			slidesPerView: 1,
			allowTouchMove: true,
			followFinger: true,
			freeMode: true,
			breakpoints: {
				1024: {
					allowTouchMove: false,
				}
			}
		} );
		swiperTestimonialsMain.init();

		$sliderTestimonialsWrap.find( '.slider-testimonials-thumb-slide' ).on( 'click', function( e ) {
			e.preventDefault();
			const $this = $( this );
			if ( $this.hasClass( 'swiper-slide-prev' ) ) {
				swiperTestimonialsThumb.slidePrev();
			} else if ( $this.hasClass( 'swiper-slide-next' ) ) {
				swiperTestimonialsThumb.slideNext();
			}
		} );
	};
	sliderTestimonials();

};

themeUtils.loadBlock( initBlock, 'reviews', '.section-reviews' );
